
/* eslint-disable indent */
import { computed } from "vue";
import { useImageProperties } from "@libraryComposables/useImageProperties";
import useIsMobile from "@libraryComposables/useIsMobile";
import { useSocialIcons } from "@libraryComposables/useSocialIcons";
import { useStore } from "@libraryHelpers/compositionApi";
import { renderData } from "@libraryHelpers/dataComponents.js";

export default {
	name: "CoreBlockContentCard",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		dataSite: {
			type: Object,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const store = useStore();
		const { state } = store;
		const mediaSizingFlag =
			state?.settings?.featureFlags?.ContentCardMediaSizing;

		const { imageSrc: mediaSrc, imageAlt: mediaAlt } = useImageProperties(
			props,
			"media",
			null
		);

		const {
			blockFacebook,
			blockLinkedin,
			blockTwitter,
			blockYoutube,
			hasSocialIcons,
		} = useSocialIcons(props);

		const { isMobile } = useIsMobile();

		const contentLibraryItems = computed(() => {
			return props.settings?.contentLibraryItems || [];
		});

		const overlineType = computed(() => {
			const containsHTML = (str) => /<[^>]*>/.test(str);

			if (containsHTML(props.settings?.overline)) {
				return "rich-text";
			}

			return "text";
		});

		const autoPlayProp = computed(() => {
			return props.settings?.autoPlay || false;
		});

		const loopProp = computed(() => {
			return props.settings?.loop || false;
		});

		const overlineText = computed(() => {
			return props.settings?.overline
				? renderData(props.settings?.overline, props.dataSite)
				: null;
		});

		const headlineText = computed(() => {
			return props.settings?.headline
				? renderData(props.settings?.headline, props.dataSite)
				: null;
		});

		const bodyText = computed(() => {
			// Standardized name is 'bodyText', but some sites still have 'richText'
			const textValue =
				props.settings?.bodyText || props.settings?.richText;
			return textValue ? renderData(textValue, props.dataSite) : null;
		});

		const disclosureText = computed(() => {
			return props.settings?.disclosure
				? renderData(props.settings.disclosure, props.dataSite)
				: null;
		});

		const mediaMetadata = computed(() => {
			const background = props.settings?.media;
			const mediaSrc =
				background && typeof background === "string"
					? props.settings?.mediaMetadata?.media
					: props.settings?.media;
			if (!mediaSrc) return { src: background };
			const { crop } = mediaSrc;
			return { src: crop ? crop : mediaSrc };
		});

		const wrapText = computed(() => {
			return props.settings?.variants?.wrapText || false;
		});

		const mediaHeight = computed(() => {
			if (mediaSizingFlag) {
				return (
					props.settings?.mediaHeight ||
					(wrapText.value ? "auto" : "100%")
				);
			}
			return props.settings?.mediaHeight || "100%";
		});

		const mediaWidth = computed(() => {
			return props.settings?.mediaWidth || "auto";
		});

		const validMediaWidth = computed(() => {
			if (mediaWidth.value === "auto") {
				return false;
			}else {
				return /^(\d+)(px|%|em|rem)$/.test(mediaWidth.value);
			}
		});

		const hasButtons = computed(() => {
			return hasPrimaryButton.value || hasSecondaryButton.value;
		});

		const hasPrimaryButton = computed(() => {
			return props.settings?.primaryButton || false;
		});

		const hasSecondaryButton = computed(() => {
			return props.settings?.secondaryButton || false;
		});

		const secondaryButtonAsPrimary = computed(() => {
			return props.settings?.variants?.["secondary-as-primary"] || false;
		});

		const secondaryTypeText = computed(() => {
			return props.settings?.secondaryTypeText ?? false;
		});

		const imageLocation = computed(() => {
			return props.settings.variants["align-image"];
		});

		const imageLocationHorizontal = computed(() => {
			return (
				imageLocation.value === "image-left" ||
				imageLocation.value === "image-right"
			);
		});

		const columnOffset = computed(() => {
			return props.settings.variants["column-offset"] || false;
		});

		const mediaContainerStyles = computed(() => {
			return {
				width: mediaWidth.value,
				height: mediaHeight.value,
				...(imageLocationHorizontal.value &&
					(mediaSizingFlag || wrapText.value) && {
						"flex-basis": `${
							mediaWidth.value === "auto"
								? "50%"
								: mediaWidth.value
						}`,
						width: `${
							mediaWidth.value === "auto"
								? "auto"
								: mediaWidth.value
						}`,
						// 24px to account for the container gap
						...(mediaSizingFlag &&
							!isMobile.value && {
								maxWidth: "calc(75% - 24px)",
							}),
					}),
				...(columnOffset.value && { width: "unset", height: "unset" }),
			};
		});

		const textBlockStyles = computed(() => {
			if (mediaSrc.value && imageLocationHorizontal.value) {
				return {
					flexBasis: `calc(100% - ${
						mediaWidth.value === "auto" ? "50%" : mediaWidth.value
					})`,
					// 24px to account for the container gap
					...(mediaSizingFlag &&
						!isMobile.value && { minWidth: "calc(25% - 24px)" }),
				};
			}
			return {};
		});

		return {
			contentLibraryItems,
			overlineText,
			overlineType,
			headlineText,
			bodyText,
			disclosureText,
			mediaSrc,
			mediaAlt,
			mediaMetadata,
			wrapText,
			mediaHeight,
			mediaWidth,
			validMediaWidth,
			hasButtons,
			hasPrimaryButton,
			hasSecondaryButton,
			secondaryButtonAsPrimary,
			secondaryTypeText,
			hasSocialIcons,
			blockFacebook,
			blockLinkedin,
			blockTwitter,
			blockYoutube,
			imageLocation,
			imageLocationHorizontal,
			columnOffset,
			isMobile,
			autoPlayProp,
			loopProp,
			mediaContainerStyles,
			textBlockStyles,
		};
	},
};
